export const IMS_WEBSITE = 'https://troovite.com/';


// LOCALHOST
//export const BASEURL_IMG = 'http://3.145.127.65/resources';
//export const BASEURL_RESOURCES = 'http://3.145.127.65/resources';
//export const SOCKET_URL = 'ws://3.145.127.65:8081/api/v1/ws';
//export const HTTP_MESSAGE_URL = 'http://3.145.127.65:8081/api/v1';
//export const BASEURL = 'http://localhost:3000';

//export const BASEURL_IMG = 'https://troovite.com/resources';
//export const BASEURL_RESOURCES = 'https://troovite.com/resources';
//export const SOCKET_URL = 'wss://troovite.com:8081/api/v1/ws';
//export const HTTP_MESSAGE_URL = 'https://troovite.com:8081/api/v1';

// PROD CONFIG33
export const BASEURL = 'http://erp.troovite.com';
export const BASEURL_IMG = 'https://troovite.com/resources';
export const BASEURL_RESOURCES = 'https://troovite.com/resources';
export const SOCKET_URL = 'wss://troovite.com:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'https://troovite.com:8081/api/v1';
