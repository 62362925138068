import React, {useEffect, useMemo, useRef, useState} from "react";
import moment, {Moment} from "moment";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import {Jour} from "../../constants/type/Jour";
import {useStylesChilday} from "./jss/child-jss";
import _ from "lodash";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    ChildImsDayStatus,
    ImsDay,
    IMSDAY_CONSTANT,
    imsDayCustomOptions,
    imsDayDataImpl,
    imsDaySchema
} from "../../constants/type/ImsDay";
import {ClasseRoom, CLASSEROOM_CONSTANT} from "../../constants/type/ClasseRoom";
import {Child, childDataImpl} from "../../constants/type/Child";
import {
    loadMenuJourData,
    loadMenuJourDataById,
    processingChildren,
    processingChildStuffs,
    processingEmployees,
    processingJobs
} from "../../helpers/loader_helpers";
import {dataMenuJourDataImpl, MenuJourData} from "../../constants/type/MenuJourData";
import {dataMenuCantineDataImpl, MenuCantineData} from "../../constants/type/MenuCantineData";
import {request} from "../../helpers/axios_helpers";
import ChildDayForm from "./ChildDayForm";
import ChildDayList, {loadImsDayOfClasse} from "./ChildDayList";
import {MRT_Row} from "material-react-table";
import {ChildStuff, CHILDSTUFF_CONSTANT} from "../../constants/type/ChildStuff";
import {ImsDayChildStuff, imsDayChildStuffDataImp} from "../../constants/type/ImsDayChildStuff";
import {Profils, PROFILS_CONSTANT} from "../../constants/type/Profils";
import {isNotObject, isPositiveValue} from "../../helpers/helpers";
import {Fonction} from "../../constants/type/Fonction";
import {Employee} from "../../constants/type/Employee";
import {RoleUser} from "../../constants/type/RoleUser";
import {useDispatch, useSelector} from "react-redux";
import {getErrorMessage} from "../../helpers/errorManager";
import {pageFormImpl, setPageForm} from "../../features/page/pageSlice";
import {mixLoad} from "../../helpers/loader_cache_helpers";
import {useMyMaterialReactTable} from "../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {getMyReactTableDefaultOptions} from "../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";


interface UserType {
    id: number,
    firstName: string;
    lastName: string;
    profile: string;
    class?: string;
    username?: string;
    image?: string;
}

const jourList = [Jour.DIMANCHE, Jour.LUNDI, Jour.MARDI, Jour.MERCREDI, Jour.JEUDI, Jour.VENDREDI, Jour.SAMEDI];
//

const useBusinessLogic = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses, activeLang} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state: any) => state.user.user));
    const pageForm = useSelector((state:any) => state.page.pageForm);
    const jobsRef = useRef<Map<number,Fonction>>(new Map());
    const employeesRef  = useRef<Employee[]>([]);
    const employeesByFonctionRef = useRef<Employee[]>([]);
    const teacherRef = useRef<Employee | null | undefined>(null);
    const classeIdRef = useRef<number>(0);
    const isTeacherRef = useRef<boolean>(false);

    const mytableState = useMyMaterialReactTable({tableId:'ChildDayList', customOptions:imsDayCustomOptions,  updateId:5}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<ImsDay>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    //const [dayMenu, setDayMenu] = useState(moment());
    const [showTableContent, setShowTableContent] = useState<boolean>(false);
    const [numericChange, setNumericChange] = useState<number>(0);
    const [showContent, setShowContent] = useState<boolean>(false);
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(false);
    const [initialized, setInitialized] = useState<boolean>(false);
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [imsDay, setImsDay] = useState<ImsDay>(_.cloneDeep(imsDayDataImpl));
    const [childImsDayStatusList, setChildImsDayStatusList] = useState<ChildImsDayStatus[]>([]);

    const profilsRef : React.MutableRefObject<Profils[]> = useRef<Profils[]>([]);
    const [menuJourData, setMenuJourData] = useState<MenuJourData>(_.cloneDeep(dataMenuJourDataImpl));
    const menuCantineDataRef = useRef<MenuCantineData>(_.cloneDeep(dataMenuCantineDataImpl));
    const [imsDayList, setImsDayList] = useState<ImsDay[]>([]);
    const [childStuffList, setChildStuffList] = useState<ChildStuff[]>([]);
    const childStuffListRef = useRef<ChildStuff[]>([]);
    const classeRoomRef = useRef<ClasseRoom[]>([]);
    const childrenRef  = useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());
    const childrenClasseRef  = useRef<Child[]>([]);
    const searchPlaceholderRef =useRef<string>( activeLang === 'en' ? 'Search by name' : 'Recherche par nom');
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [menuToChange, setMenuToChange] = useState<number>(moment().valueOf());
    const [classeSel, setClasseSel] = useState<number>(classeIdRef.current);

    const formRef = useRef<any>(useForm({
        defaultValues: _.cloneDeep(imsDayDataImpl),
        // @ts-ignore
        resolver: yupResolver(imsDaySchema),
    }));
    const {errors} = formRef.current.formState;


    const handleImsDaySieste = (event: React.SyntheticEvent, value: any) => {
        const imsjour = _.cloneDeep(imsDay);
        imsjour.sieste = value ;
        setImsDay(imsjour);

        formRef.current.setValue('sieste', value);
    }
    const setChild = (item:Child | null, thisImsDay:ImsDay) => {
        if(_.isObject(item) === false){
            item = _.cloneDeep(childDataImpl);
        }
        // @ts-ignore
        const imsjour = _.cloneDeep(thisImsDay);
        // @ts-ignore
        imsjour.enfantId = item.id ?? 0 ;
        // @ts-ignore
        imsjour.childForm = item ;
        // @ts-ignore
        imsjour.childNomPrenomForm =  `${imsjour.childForm.childNomPrenomForm}`;
        // @ts-ignore
        imsjour.classeNameForm  = imsjour.childForm.eleves[0]?.classe?.nom ?? '' ;
        // @ts-ignore
        imsjour.classeId        = imsjour.childForm.eleves[0]?.classe?.id ?? 0 ;
        // @ts-ignore
        imsjour.childphotoForm = imsjour.childForm.childphotoForm ;

        formRef.current.setValue('enfantId', imsjour.enfantId);
        formRef.current.setValue('childForm', imsjour.childForm);
        formRef.current.setValue('childNomPrenomForm', imsjour.childNomPrenomForm);
        formRef.current.setValue('classeNameForm', imsjour.classeNameForm);
        formRef.current.setValue('classeId', imsjour.classeId);
        formRef.current.setValue('childphotoForm', imsjour.childphotoForm);
        setImsDay(imsjour);
        return imsjour ;
    }

    const handleChildChange = (event: React.SyntheticEvent, value: Child) => {
        setChild(value, imsDay);
        _.delay(function(text) {
           setNumericChange(new Date().getTime());
        }, 15, 'later');
    }

    const handleRowsDelete = (rows:any) => {
        const dataSelected:any = [];
        const selectedIds:any = [];
        rows.map((row:any) => {
            const data = imsDayList[row.index];
            dataSelected.push(data);
            selectedIds.push(data.id);
        });

        seTableDataLoading(true);
        request('DELETE', '/extra/imsday/bouquet', selectedIds).then((response) => {
            const itemsimsdays = _.cloneDeep(imsDayList);
            const itemList  = _.filter(itemsimsdays, function(item) {
                return !selectedIds.includes(item.id);
            });
            const imsdaysSorts =_.reverse(_.sortBy(itemList, ['theDate'])) ;
            seTableDataLoading(false);
            setImsDayList(imsdaysSorts);
            setTableKeyValue(moment().valueOf());
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);
        }).catch((error) => {
            snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            seTableDataLoading(false);
        });
    }
    const loadImsDay = (id:number) => {
        return new Promise<ImsDay>(resolve => {
            request("GET",  `/extra/imsday/${id}`, {}).then((response) => {
                resolve(response.data);
            }).catch((error) => {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(_.cloneDeep(imsDayDataImpl));
            })
        });
    }
    const loadImsDayOfEnfant = (enfantId:number, theDate:number) => {
        return new Promise<ImsDay|undefined>(resolve => {
            request("GET",  `/extra/imsday/enfant/${enfantId}/${theDate}`, {}).then((response) => {
                resolve(response.data );
            }).catch((error) => {
               snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(undefined);
            })
        });
    }

    const editImsDay = (row:MRT_Row<ImsDay>) => {
        //console.log(row.original);
        //const data = imsDayList[row.index];
        afficherImsDay(_.cloneDeep(row.original));

        _.delay(function(text) {
            setNumericChange(new Date().getTime());
        }, 15, 'later');
        //setShowForm(true);
    }

    const reinitImsDay = (data:ImsDay) => {
        const newImsDay = _.cloneDeep(imsDayDataImpl) ;
        newImsDay.theDate = data?.theDate;
        newImsDay.menuJourId = data?.menuJourId;
        newImsDay.classeId = data?.classeId;
        newImsDay.theDateForm = data?.theDateForm;
        newImsDay.childForm = data?.childForm;
        newImsDay.classeId = data?.childForm?.eleves[0]?.classe?.id ?? data.classeId;

        return newImsDay ;
       // afficherImsDay(newImsDay);
    }

    const afficherImsDay = (data:ImsDay) => {
        if(_.isNull(data) || _.isUndefined(data) || _.isObject(data?.theDateForm) === false){
            return ;
        }
        const imsjour = _.cloneDeep(data);
        setImsDay(imsjour);
        formRef.current.reset(imsjour);
        updateCantineMenuDay(imsjour);
    }
    const addImsDay = () => {
      //  setShowForm(false);
        setLoadingForm(true);
        setImsDay(_.cloneDeep(imsDayDataImpl));
        setShowForm(true);
        setLoadingForm(false);

        _.delay(function(text) {
            setNumericChange(new Date().getTime());
        }, 15, 'later');
    }
    const returnToList = () => {
        setShowForm(false);
    }

    const updateMenuJourData = (imsJour:ImsDay, menuJourData: MenuJourData | null ) => {
        if(_.isNull(menuJourData)){
            setMenuJourData(_.cloneDeep(dataMenuJourDataImpl));
            menuCantineDataRef.current = _.cloneDeep(dataMenuCantineDataImpl) ;
            menuCantineDataRef.current.nom = activeLang === 'en' ? `No menu found` : `Aucun menu trouvé`;
            formRef.current.setValue('menuJourId',  0);
            return ;
        }
        const imsdayo = _.cloneDeep(imsJour);
        imsdayo.menuJourId = menuJourData?.id ?? 0 ;
        setImsDay(imsdayo);
        setMenuJourData(menuJourData);
        formRef.current.setValue('menuJourId', imsdayo.menuJourId);

        const childStuffList = _.map(_.cloneDeep(childStuffListRef.current), (item:ChildStuff) => {
            const childstuff = _.find(imsdayo.imsDayChildStuffs, {'childStuffId': item.id ?? 0}) ?? _.cloneDeep(imsDayChildStuffDataImp);
            item.activer = isPositiveValue(childstuff.quantite);
            item.quantite = childstuff.quantite ?? 0;
            return item ;
        });
        setChildStuffList(childStuffList);


        // @ts-ignore
        if(_.isObject(menuJourData.menuCantineDatas[0])){
            // @ts-ignore
            menuCantineDataRef.current = menuJourData?.menuCantineDatas[0];
        }
    }


    const updateCantineMenuDayById = (id: number) => {
        setShowContent(false);
        menuCantineDataRef.current = _.cloneDeep(dataMenuCantineDataImpl) ;
        // @ts-ignore
        loadMenuJourDataById(_.cloneDeep(imsDay), updateMenuJourData, id).then( value => setShowContent(true) );
    }

    const updateCantineMenuDayWithTheDate = (imsDay:ImsDay, daychoose:Moment) => {
        const imsjour = _.cloneDeep(imsDay);

        imsjour.theDateForm = daychoose ;
        setImsDay(imsjour);
        formRef.current.setValue('theDateForm', daychoose);
        updateCantineMenuDay(imsjour);
    }

    const updateCantineMenuDay = (imsjour:ImsDay) => {
        if(_.isObject(imsjour.theDateForm) === false){
            return ;
        }
        setShowContent(false);
        updateMenuJourData(imsjour, null);
        const jour = jourList[imsjour.theDateForm.day()];
        loadMenuJourData(imsjour, updateMenuJourData, imsjour.theDateForm.valueOf(), jour)
            .then( value => {
                setShowContent(true);
            } ).catch( e => {setShowContent(true);  snackbarShowMessage("Not found", 'error')});
    }


    const handleNapStartChange = (day: any) => {
        const imsjour = _.cloneDeep(imsDay);

        let heure = moment(day.valueOf());
        const imsheurmn = imsjour.theDateForm.clone() ;
        imsheurmn.set('hour', heure.hours());
        imsheurmn.set('minute', heure.minutes());
        imsjour.timeDebutSiesteForm = imsheurmn ;
        setImsDay(imsjour);

        formRef.current.setValue('timeDebutSiesteForm', imsheurmn);
    };

    const handleNapEndChange = (day: any) => {
        const imsjour = _.cloneDeep(imsDay);

        let heure = moment(day.valueOf());
        const imsheurmn = imsjour.theDateForm.clone() ;
        imsheurmn.set('hour', heure.hours());
        imsheurmn.set('minute', heure.minutes());
        //heure = imsjour.theDateForm.clone().add(heure.hours(), 'hours').add(heure.minutes(), 'minutes');

        imsjour.timeFinSiesteForm = imsheurmn ;
        setImsDay(imsjour);

        formRef.current.setValue('timeFinSiesteForm', imsheurmn);
    };

    const handleObsCantineChange = (event: React.ChangeEvent<HTMLInputElement>, value:any) => {
        // @ts-ignore
        const imsjour = _.cloneDeep(imsDay);
        imsjour.observationCantine = value ;
        setImsDay(imsjour);
        formRef.current.setValue('observationCantine', value);
    };

    const onSubmit = async (logicRacine:any,data: FormData) => {
        // @ts-ignore
        const item: ImsDay = _.cloneDeep(data);

        if(isPositiveValue(item.menuJourId) === false){
            const erreur = activeLang === 'en' ? `You can't save IMS DAY without dish.` : `Vous ne pouvez enregistrer l'IMS DAY sans choisir le repas du jour.`;
            snackbarShowMessage(erreur, 'error');
            return ;
        }
        const toSend = IMSDAY_CONSTANT.convertToBackEnd(item);

        return new Promise<boolean>((resolve, reject) => {
            const method = _.isNull(toSend.id) ? 'POST' : 'PUT';
            let params = '';
            if(method === 'PUT'){
                params = `/${toSend.id}`;
            }

            const imsdaychildstuffList: ImsDayChildStuff[] = [];
            childStuffList.forEach((data:ChildStuff) => {
                if(data.activer){
                    const imsdaychildstuff = _.cloneDeep(imsDayChildStuffDataImp);
                    imsdaychildstuff.activer = true ;
                    imsdaychildstuff.nom = data.nom ;
                    imsdaychildstuff.quantite = data.quantite ;
                    imsdaychildstuff.common = data.common ;
                    // @ts-ignore
                    imsdaychildstuff.childStuffId = data.id ;
                    // @ts-ignore
                    imsdaychildstuff.imsDayId = toSend.id ;
                    imsdaychildstuffList.push(imsdaychildstuff);
                }
            })
            toSend.imsDayChildStuffs = imsdaychildstuffList;
            setLoadingForm(true);
            request(method, `/extra/imsday${params}`, toSend).then((response) => {
                snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                /*if(method === 'POST'){
                    addImsDay();
                }*/
                resolve(true);
                setLoadingForm(false);
                _.delay(function(text) {
                setNumericChange(new Date().getTime());
            }, 15, 'later');
            }).catch((error) => {
                snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                setLoadingForm(false);
            });
        });

    };


    return {
        userRef, jobsRef, formRef, onSubmit, editImsDay, imsDay, snackbarShowMessage, theme, styleClasses,
        activeLang,loadImsDayOfEnfant,errors,classeRoomRef,setShowForm,showForm,childStuffListRef,
        searchPlaceholderRef,childrenRef,childrenClasseRef,addImsDay,handleImsDaySieste,loadImsDay, afficherImsDay,
        handleChildChange,menuJourData,menuCantineDataRef,handleObsCantineChange,profilsRef,pageForm,setChild,
        handleNapEndChange,handleNapStartChange,imsDayList, setImsDayList,tableKeyValue, initialized, setInitialized,
        showContent,setShowContent,handleRowsDelete,returnToList,updateCantineMenuDay,childStuffList,setChildStuffList,
        employeesRef,employeesByFonctionRef,teacherRef,classeIdRef,isTeacherRef,tableDataLoading,childrenMapRef,
        seTableDataLoading,dataCaches, dispatch, updateCantineMenuDayWithTheDate,loadingForm,setLoadingForm,
        tableConfigRef, classeSel,  setClasseSel,reinitImsDay, childImsDayStatusList, setChildImsDayStatusList,
        numericChange, setNumericChange,
        ...logicMRT
    }
}
export const ChilDayContext = React.createContext({});

const ChildDayAtIms = (props: any) => {
    const {theme, locale} = props;
    const activeLang = locale.split('-')[0];
    const styleClasses = useStylesChilday();
    const logic = useBusinessLogic({activeLang, styleClasses, ...props});

    useEffect(() => {
        checkPageToShowFromNotification(logic);
    }, [logic.pageForm]);

    useEffect(() => {
        //moment.tz.setDefault("Africa/Abidjan");
        moment.locale(activeLang);
        initPostConstruct(logic);
    }, []);



    return (
        <div className={styleClasses.root}>
            {
                logic.showForm &&
                <ChilDayContext.Provider value={logic}>
                    <ChildDayForm
                        locale={locale}
                        theme={theme}
                        styleClasses={styleClasses}
                    />
                </ChilDayContext.Provider>
            }
            {
                !logic.showForm &&
                <ChilDayContext.Provider value={logic}>
                    <ChildDayList
                        locale={locale}
                        theme={theme}
                        styleClasses={styleClasses}
                    />
                </ChilDayContext.Provider>
            }
        </div>
    )
}

/*

const updateImsDayOfClasse = (logic:any) => {
    logic.seTableDataLoading(true);
    logic.setLoadingForm(true);
    loadImsDayOfClasse(logic, logic.classeIdRef.current).then(value => {

        if(_.isObject(logic.pageForm)){
            // @ts-ignore
            if(logic.pageForm.cle === 'ims_day' && isPositiveValue(logic.pageForm.id) ){
                const dataId = logic.pageForm.id ;
                logic.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
                // @ts-ignore
                logic.loadImsDay(dataId).then((data:any) => {
                    if(isPositiveValue(data.id)){
                        logic.afficherImsDay(IMSDAY_CONSTANT.convertToFrontEnd(logic, data));
                    }
                    logic.setLoadingForm(false);
                    logic.seTableDataLoading(false);
                }).catch((error:any) => {
                    logic.setLoadingForm(false);
                    logic.seTableDataLoading(false);
                });
            }else{
                logic.setLoadingForm(false);
                logic.seTableDataLoading(false);
            }
        }else{
            logic.setLoadingForm(false);
            logic.seTableDataLoading(false);
        }
    }) ;
}
*/

const checkPageToShowFromNotification = (logic:any) => {
    if(isNotObject(logic.pageForm)){
        return ;
    }
    // @ts-ignore
    if(logic.pageForm.cle === 'ims_day' && isPositiveValue(logic.pageForm.id) ){
        const dataId = logic.pageForm.id ;
        logic.dispatch(setPageForm(_.cloneDeep(pageFormImpl)));
        logic.reinitImsDay(logic.imsDay);
        logic.setLoadingForm(true);
        // @ts-ignore
        logic.loadImsDay(dataId).then((data:any) => {
            if(isPositiveValue(data.id)){
                logic.afficherImsDay(IMSDAY_CONSTANT.convertToFrontEnd(logic, data));
            }
            logic.setLoadingForm(false);
            logic.seTableDataLoading(false);
            logic.setInitialized(true);
        }).catch((error:any) => {
            logic.setLoadingForm(false);
            logic.seTableDataLoading(false);
            logic.setInitialized(true);
        });
    }else {
        logic.setLoadingForm(false);
        logic.seTableDataLoading(false);
        logic.setInitialized(true);
    }

}
//TODO: Lazy load for ims day
const initPostConstruct = (logic:any):any => {
    //logic.setLoadingForm(true);
    logic.seTableDataLoading(true);
    logic.setInitialized(false);
    const requestbody = ['classes', 'profils', 'fonctions', 'enfants', 'employees', 'childstuff'];
    const convertToFrontEnd = (data:any) => {
        const itemscl : ClasseRoom[]   = data.classes.content ?? [];
        logic.classeRoomRef.current  = _.sortBy(_.map(itemscl,  CLASSEROOM_CONSTANT.convertToFrontEnd), ['nom']);

        const itemsp : Profils[]   = data.profils.content ?? [];
        logic.profilsRef.current  = _.sortBy(_.map(itemsp,  item => PROFILS_CONSTANT.convertToFrontEnd(logic, item)), ['nom']);

        const itemsprof : Fonction[]   = data.fonctions.content ?? [];
        logic = processingJobs(logic, itemsprof);

        const itemchilds : Child[]   = data.enfants.content ?? [];
        logic = processingChildren(logic, itemchilds);

        const itememployees : Employee[]   = data.employees.content ?? [];
        logic = processingEmployees(logic, itememployees);

        const itemchildstuff : ChildStuff[]   = data.childstuff.content ?? [];
        const itemchildstufFilter = _.filter(itemchildstuff, {'activer':true});
        logic = processingChildStuffs(logic, itemchildstufFilter);
    };

    mixLoad(logic, requestbody, convertToFrontEnd).then(value => {
        if(logic?.userRef?.current?.role === RoleUser.TEACHER){
            logic.isTeacherRef.current = true;
            const employeeList = _.cloneDeep(logic?.employeesByFonctionRef?.current);
            const sz = employeeList.length;
            let employee:Employee;
            const person = logic.userRef.current?.userDetails?.personDetails?.person ;
            for (let i = 0; i < sz; i++) {
                employee = employeeList[i];
                if(employee.id !== person.id){
                    continue ;
                }
                logic.teacherRef.current = employee;
                break ;
            }
            logic.classeIdRef.current = logic.userRef?.current?.userDetails?.personDetails?.teacherClasses[0]?.classeId ?? 0;
            logic.setClasseSel(logic.classeIdRef.current);

            logic.childrenClasseRef.current = _.filter(logic.childrenClasseRef.current, item => {
                return item.eleves[0]?.classe?.id === logic.classeIdRef.current ;
            });

            checkPageToShowFromNotification(logic);
        }
        logic.setLoadingForm(false);
        logic.seTableDataLoading(false);
        logic.setInitialized(true);

    }).catch(error => {
        logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
    });
    return logic;
}

const loadChildStuffs = (logic:any) => {
    const filterApi = {
        filters:[
            {
                key: "activer",
                operator: "EQUAL",
                field_type: "BOOLEAN",
                value: true
            }
        ]
    };
    const params = btoa(JSON.stringify(filterApi));
    return new Promise<boolean>(resolve =>
        request("GET",  `/corebase/childstuff?filter=${params}`, {}).then((response) => {
            const items : ChildStuff[]   = response.data._embedded?.childStuffDTOModelList ?? [];
            const datas =  _.map(items, item => CHILDSTUFF_CONSTANT.convertToFrontEnd(logic, item));
            logic.childStuffListRef.current = _.sortBy(datas, ['nom']) ;
            resolve(true);
        }).catch((error) => {
            logic.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
            resolve(false);
        })
    );
}

export default withSnackbar(ChildDayAtIms);
