import React, {useContext, useEffect} from "react";
import {SchoolDocumentContext} from "./SchoolDocument";
import {Button, Grid, IconButton} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import InputField from "../../components/ui/form/InputField";
import _ from "lodash";
import {DocumentScolaire, DOCUMENTSCOLAIRE_CONSTANT} from "../../constants/type/DocumentScolaire";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";
import schoolDocStateUpdate from "./businesslogic/SchoolDocumentStateUpdate";
import schoolDocumentBusinessLogic from "./businesslogic/SchoolDocumentBusinessLogic";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {isPositiveValue} from "../../helpers/helpers";

function SchoolDocumentToolBar(props:any) {
    const logic: any = useContext(SchoolDocumentContext);
    const styleClasses = logic.styleClasses ;

    const [disableSaveBtn, setDisableSaveBtn] = React.useState<boolean>(false);
    const [disabledInput, setDisabledInput] = React.useState<boolean>(false);
    const handleClickAddFolder = () => schoolDocumentBusinessLogic.handleAddFolder(logic);
    const handleDelete = () => schoolDocumentBusinessLogic.handleDeleteFolder(logic);

    const handleUpdateFolder = () => schoolDocumentBusinessLogic.handleUpdateFolder(logic);
    
    const handleNomChange = ( event: any) => {
        const data = _.cloneDeep(logic.docFolderSel);
        data.nom = event.target.value;
        schoolDocStateUpdate.docFolderSel(logic, data);
    };
    const handleSave = async () => {
        schoolDocStateUpdate.processingFolderStateNormBtn(logic, true);
        // @ts-ignore
        const dataToSend: DocumentScolaire = _.cloneDeep(logic.docFolderSel);

        // @ts-ignore
        const toSend = DOCUMENTSCOLAIRE_CONSTANT.convertToBackEnd(logic, dataToSend);
        schoolDocumentBusinessLogic.renameFolder(
            logic,
            messages,
            toSend).then((response:any) => {
            schoolDocStateUpdate.processingFolderStateNormBtn(logic, false);
        }).catch((e:any) => schoolDocStateUpdate.processingFolderStateNormBtn(logic, false));
    };

    useEffect(() => {
        setDisableSaveBtn(logic.docFolderSel.nom.length < 2);
        setDisabledInput(_.isNull(logic.docFolderSel.id) || logic.docFolderSel.isOwnerForm===false);
        return () => {};
    }, [logic.docFolderSel]);

    if(logic.showFiles){
        return <></>;
    }
    return(
        <div style={{display:"flex"}}>
            <Grid container spacing={2}>
                <Grid item md={8} xs={8} sx={{textAlign:'left', alignItems:'left'}}>
                    <Button variant={"contained"}
                            component="label"
                            size="small"
                            color="primary"
                            sx={{textTransform: 'none', mt:0.5, mr: { sm: 2 } }}
                            startIcon={<CreateNewFolderIcon />}
                            onClick={handleClickAddFolder}>
                        <FormattedMessage id={messages.doc_add_folder} />
                    </Button>
                    <Button
                        component="label"
                        size="small"
                        color="secondary"
                        sx={{textTransform: 'none', mt:0.5, mr: { sm: 2 } }}
                        variant="contained"
                        onClick={handleUpdateFolder}
                        disabled={!isPositiveValue(logic.docFolderSel.id)}
                        startIcon={<SystemUpdateAltIcon />}>
                        <FormattedMessage id={messages.doc_update_folder} />
                    </Button>
                    <Button
                        component="label"
                        size="small"
                        color="error"
                        sx={{textTransform: 'none', mt:0.5, mr: { sm: 2 } }}
                        variant="contained"
                        onClick={handleDelete}
                        disabled={!isPositiveValue(logic.docFolderSel.id)}
                        startIcon={<FolderDeleteIcon />}>
                        <FormattedMessage id={messages.doc_del_folder} />
                    </Button>
                </Grid>
                <Grid item md={4} xs={4} sx={{textAlign:'right', alignItems:'right'}}>
                    <div className={styleClasses.inputField} style={{display:"flex", alignItems:'right', justifyContent:'right' }}>
                        <InputField
                            label={<FormattedMessage id={messages.doc_folder} />}
                            size="small"
                            color={"secondary"}
                            disabled={disabledInput}
                            value={logic.docFolderSel.nom}
                            sx={{ width: 1, float:'right', marginRight:2 }}
                            onChange={handleNomChange}
                        />
                        {
                            logic.docFolderSel.isOwnerForm  &&
                            <>
                                <IconButton
                                    aria-label="save"
                                    color="secondary"
                                    disabled={disableSaveBtn}
                                    onClick={handleSave}
                                    sx={{float:'right', marginRight:2}}>
                                    {
                                        logic.savingStateBtn &&
                                        <RefreshIcon />
                                    }
                                    {
                                        !logic.savingStateBtn &&
                                        <SaveIcon />
                                    }
                                </IconButton>
                            </>
                        }
                    </div>
                    <div
                        className={styleClasses.inputField}
                        style={{display:"flex", fontStyle:'italic', alignItems:'left', justifyContent:'left', textTransform:'lowercase' }}>
                        {logic.docFolderSel?.documentScolaireFichiers?.length ?? 0}  <FormattedMessage id={messages.doc_file} />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

export default SchoolDocumentToolBar ;